<template>
  <div>
    <div v-if="intro.fossils &&  $route.params.type === 'fossils'">
      <text-block
        :data="intro.fossils"
      />
    </div>
    <div 
      ref="masonry"
      class="grid gap-5 auto-rows-auto pt-5 pb-2 lg:pb-2"
      :class="[
        {'grid-cols-1 lg:grid-cols-2 md:grid-cols-2 left-1/2 right-1/2 -ml-48vw -mr-48vw max-w-96vw relative': (type === 'explains')},
        {'grid-cols-1 lg:grid-cols-3 md:grid-cols-2': (type === 'stories' || type === 'news' || type === 'fossils')}
      ]"
    >
      <div
        v-for="(item, index) in data"
        :key="index"
      >
        <div>
          <router-link v-if="item.featured.image" class="no-underline" :to="{ name: 'Single', params: { slug: item.slug }}">
              <div v-if="item.featured.image">
                <img @load="imageRendered" :src="api + item.featured.image.formats.large.url"/>
              </div>
              <h2 v-html="item.title"/>
              <div v-if="item.featured.excerpt">
                {{ item.featured.excerpt }}
              </div>
          </router-link>
          <div v-else-if="item.featured.video">
            <div>
              <video-block @load="videoRendered" :data="item.featured"/>
            </div>
            <h2 v-html="item.title"/>
            <div v-if="item.featured.excerpt">
              {{ item.featured.excerpt }}
            </div>
          </div>
          <tags 
            v-if="item.tags"
            :tags="item.tags"
          />  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Masonry',
  props: ['data'],
  metaInfo() {
    return {
      title: this.title
    }
  },
  components: {
    VideoBlock: () => import('@/components/blocks/VideoBlock'),
    Tags: () => import('@/components/Tags.vue'),
    TextBlock: () => import('@/components/blocks/TextBlock.vue')
  },
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      imagesCount: 0,
      imageCounter: 0,
      markdownCount: 0,
      markdownCounter: 0,
      videoCount: 0,
      videoCounter: 0,
      masonryEvents: ['load', 'resize'],
    }
  },
  mounted() {
    this.calculateImageCount()
    this.masonryEvents.forEach(event => {
      window.addEventListener(event, this.resizeAllMasonryItems)
    });
  },
  destroyed() {
    this.masonryEvents.forEach(event => {
      window.removeEventListener(event, this.resizeAllMasonryItems)
    })
  },
  computed: {
    title() {
      return this.$options.filters.titleize(this.$route.params.type)
    },
    type() {
      return this.$route.params.type
    },
    intro() {
      return this.$store.getters.introTexts
    }
  },
  methods: {
    imageRendered() {
      this.imagesCount++
    },
    markdownRendered() {
      this.markdownCount++
    },
    videoRendered() {
      this.videoCount++
    },
    calculateImageCount() {
      this.data.map(item => {
        if (item.featured.image) {
          this.imageCounter++
        } else if (item.featured.video) {
          this.videoCounter++
        }
      })
    },
    resizeMasonryItem(item) {
      let grid = this.$refs.masonry,
          rowGap = parseInt(getComputedStyle(grid).getPropertyValue('grid-row-gap')),
          itemContent = item.firstElementChild,
          rowHeight = parseInt(0);

      let rowSpan = Math.ceil((itemContent.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap))

      item.style.gridRowEnd = `span ${rowSpan}`
    },
    resizeAllMasonryItems() {
      // Get all masonry child nodes
      let allItems = this.$refs.masonry.childNodes

      /* Loop through the above list and execute the spanning
      function to each masonry item */
      for (let i = 0; i < allItems.length; i++) {
        this.resizeMasonryItem(allItems[i])
        if (i === (allItems.length-1)) {
          // all blocks are loaded
          // TODO: fade in?
        }
      }
    },
  },
  watch: {
    imagesCount() {
      if (this.imagesCount === this.imageCounter) {
        this.resizeAllMasonryItems()
      }
    },
    markdownCount() {
      if (this.markdownCount === this.markdownCounter) {
        this.resizeAllMasonryItems()
      }
    },
    videoCount() {
      if (this.videoCount === this.videoCounter) {
        this.$nextTick(() => {
          this.resizeAllMasonryItems()
        })
      }
    }
  }
}
</script>